
import {Options, Vue} from "vue-class-component";

@Options({
  components: {

  },
  props: {
    content: {
      type: String,
      required: true
    },
    config: {
      required: true
    }
  },
})
export default class Subtitle extends Vue {
  getBarStyle(config: any) {
    let obj = {} as any;
    if (config === undefined) {
      config = {};
    }

    if (config.barColor !== undefined && config.barColor !== null) {
      obj['backgroundColor'] = config.barColor;
    }

    if (config.textBarColor !== undefined && config.textBarColor !== null) {
      obj['color'] = config.textBarColor;
    }

    return obj;
  }
}

