
import {Options, Vue} from "vue-class-component";
import {Helper} from "@/services/Helper";

@Options({
  components: {

  },
  props: {
    compare1: {
      required: true
    },
    compare2: {
      required: true
    }
  },

})
export default class SocialMediaStat extends Vue {
  formatNumber(value:number) {
    return Helper.numberFormat(value);
  }
}
