import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Hero = _resolveComponent("Hero")!
  const _component_GameTitle = _resolveComponent("GameTitle")!
  const _component_Subtitle = _resolveComponent("Subtitle")!
  const _component_SocialMediaStat = _resolveComponent("SocialMediaStat")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Header),
    _createVNode(_component_Hero, {
      team1: _ctx.game.team1,
      team2: _ctx.game.team2,
      game: _ctx.game
    }, null, 8, ["team1", "team2", "game"]),
    _createVNode(_component_GameTitle, {
      team1: _ctx.game.team1,
      team2: _ctx.game.team2
    }, null, 8, ["team1", "team2"]),
    _createVNode(_component_Subtitle, { content: "Statystyki drużyn w social media" }),
    _createVNode(_component_SocialMediaStat, {
      compare1: _ctx.game.team1,
      compare2: _ctx.game.team2
    }, null, 8, ["compare1", "compare2"])
  ]))
}