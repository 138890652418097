
import {Options, Vue} from "vue-class-component";

@Options({
  components: {

  },
  props: {
    team1: {
      required: true
    },
    team2: {
      required: true
    }
  }
})
export default class GameTitle extends Vue {
}
